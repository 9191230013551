var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"slim":""}},[_c('div',{staticClass:"question-title"},[_vm._v(" What position title will you offer? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('validationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"disabled":Boolean(_vm.customValueState.position)},on:{"change":function($event){return _vm.customStateSetter('position', $event === 'custom')}},model:{value:(_vm.newOffer.position),callback:function ($$v) {_vm.$set(_vm.newOffer, "position", $$v)},expression:"newOffer.position"}},[(_vm.defaultOffer.intern)?_c('v-radio',{attrs:{"label":"Intern","value":"Intern"}}):_vm._e(),(_vm.defaultOffer.fellow)?_c('v-radio',{attrs:{"label":"Fellow","value":"Fellow"}}):_vm._e(),(_vm.defaultOffer.eir)?_c('v-radio',{attrs:{"label":"Entrepreneur-in-Residence","value":"Entrepreneur-in-Residence"}}):_vm._e(),(_vm.defaultOffer.apprentice)?_c('v-radio',{attrs:{"label":"Apprentice","value":"Apprentice"}}):_vm._e(),(_vm.defaultOffer.preApprentice)?_c('v-radio',{attrs:{"label":"Pre-Apprentice","value":"Pre-Apprentice"}}):_vm._e(),(_vm.defaultOffer.preInternship)?_c('v-radio',{attrs:{"label":"Pre-Internship","value":"Pre-Internship"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Enter a custom position title","value":"custom"}})],1)]}}])}),(_vm.customState.position)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","rounded":"","maxlength":"50","counter":"50","label":"Enter a custom position title","prepend-inner-icon":"mdi-subtitles-outline"},on:{"input":function($event){return _vm.customValueSetter('position', $event !== '')}},model:{value:(_vm.newOffer.customPosition),callback:function ($$v) {_vm.$set(_vm.newOffer, "customPosition", $$v)},expression:"newOffer.customPosition"}})]}}],null,false,3067426275)}):_vm._e(),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" What will be their internship project and/or task(s)? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"disabled":_vm.customValueState.internshipProject},on:{"change":function($event){return _vm.customStateSetter('internshipProject', $event === 'custom')}},model:{value:(_vm.newOffer.internshipProject),callback:function ($$v) {_vm.$set(_vm.newOffer, "internshipProject", $$v)},expression:"newOffer.internshipProject"}},[(_vm.defaultOffer.internshipProject1)?_c('v-radio',{attrs:{"label":"Continue development of pre-internship project and/or additionally assigned tasks","value":"Continue development of pre-internship project and/or additionally assigned tasks"}}):_vm._e(),(_vm.defaultOffer.internshipProject2)?_c('v-radio',{attrs:{"label":"Continue development of pre-internship project only","value":"Continue development of pre-internship project only"}}):_vm._e(),(_vm.defaultOffer.internshipProject3)?_c('v-radio',{attrs:{"label":"Completion of pre-internship project into production","value":"Completion of pre-internship project into production"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Enter custom project scope and/or task(s)","value":"custom"}})],1)]}}])}),(_vm.customState.internshipProject)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","rounded":"","maxlength":"280","counter":"280","label":"Describe in detail the custom project scope and/or task(s)","placeholder":"Piloting pre-internship project and ...","prepend-inner-icon":"mdi-pencil-ruler"},on:{"input":function($event){return _vm.customValueSetter('internshipProject', $event !== '')}},model:{value:(_vm.newOffer.customInternshipProject),callback:function ($$v) {_vm.$set(_vm.newOffer, "customInternshipProject", $$v)},expression:"newOffer.customInternshipProject"}})]}}],null,false,1385126199)}):_vm._e(),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" What compensation will you offer? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors,"disabled":_vm.customValueState.compensation},on:{"change":function($event){return _vm.customStateSetter('compensation', $event === 'custom')}},model:{value:(_vm.newOffer.compensation),callback:function ($$v) {_vm.$set(_vm.newOffer, "compensation", $$v)},expression:"newOffer.compensation"}},[(_vm.defaultOffer.compensation7)?_c('v-radio',{attrs:{"label":"$50 Stipend (+20% w/ PilotCity employer-of-record)","value":"$50 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation8)?_c('v-radio',{attrs:{"label":"$100 Stipend (+20% w/ PilotCity employer-of-record)","value":"$100 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation9)?_c('v-radio',{attrs:{"label":"$150 Stipend (+20% w/ PilotCity employer-of-record)","value":"$150 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation1)?_c('v-radio',{attrs:{"label":"$250 Stipend (+20% w/ PilotCity employer-of-record)","value":"$250 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation2)?_c('v-radio',{attrs:{"label":"$350 Stipend (+20% w/ PilotCity employer-of-record)","value":"$350 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation3)?_c('v-radio',{attrs:{"label":"$500 Stipend (+20% w/ PilotCity employer-of-record)","value":"$500 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation4)?_c('v-radio',{attrs:{"label":"$595 Stipend (+20% w/ PilotCity employer-of-record, Recommended)","value":"$595 Stipend"}}):_vm._e(),(_vm.defaultOffer.compensation5)?_c('v-radio',{attrs:{"label":"W2 Employee (Minimum wage or more)","value":"W2 Employee (Minimum wage or more)"}}):_vm._e(),(_vm.defaultOffer.compensation6)?_c('v-radio',{attrs:{"label":"Unpaid ($0)","value":"Unpaid ($0)"}}):_vm._e(),_c('v-radio',{attrs:{"label":"Offer custom compensation ($), arrangement or gift","value":"custom"}})],1)]}}])}),(_vm.customState.compensation)?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","rounded":"","maxlength":"500","counter":"500","label":"Enter custom amount ($) with unit (ie: $750 Stipend, $16 per hour, etc), arrangement or gift","prepend-inner-icon":"mdi-currency-usd","append-icon":"mdi-gift"},on:{"input":function($event){return _vm.customValueSetter('compensation', $event !== '')}},model:{value:(_vm.newOffer.customCompensation),callback:function ($$v) {_vm.$set(_vm.newOffer, "customCompensation", $$v)},expression:"newOffer.customCompensation"}})]}}],null,false,2049270238)}):_vm._e(),_c('h2',{staticClass:"d-flex justify-center mt-12 mb-8 font-weight-bold"},[_vm._v("Additional details")]),_c('div',{staticClass:"acceptstudentdialog pa-12"},[_c('div',{staticClass:"question-title mt-10"},[_vm._v(" Who will be the employer-of-record? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors},model:{value:(_vm.newOffer.employerRecord),callback:function ($$v) {_vm.$set(_vm.newOffer, "employerRecord", $$v)},expression:"newOffer.employerRecord"}},[_c('v-radio',{attrs:{"label":"My organization will run payroll","value":false}}),_c('v-radio',{attrs:{"label":"PilotCity (20% admin fee from total budget)","value":true}})],1)]}}])}),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" Is there possibility of continuation or re-hire? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors},model:{value:(_vm.newOffer.continuation),callback:function ($$v) {_vm.$set(_vm.newOffer, "continuation", $$v)},expression:"newOffer.continuation"}},[_c('v-radio',{attrs:{"label":"Yes (Recommended)","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)]}}])}),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" Is a driver's license, car and/or transport to workplace required? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"error-messages":errors},model:{value:(_vm.newOffer.licenseRequirement),callback:function ($$v) {_vm.$set(_vm.newOffer, "licenseRequirement", $$v)},expression:"newOffer.licenseRequirement"}},[_c('v-radio',{attrs:{"label":"No (Recommended for digital internship)","value":false}}),_c('v-radio',{attrs:{"label":"Yes","value":true}})],1)]}}])}),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" What are the minimum & maximum total work hours? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('v-row',{staticClass:"d-flex mt-3"},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.minHours,"label":"Minimum hours","outlined":"","rounded":""},model:{value:(_vm.newOffer.minHours),callback:function ($$v) {_vm.$set(_vm.newOffer, "minHours", $$v)},expression:"newOffer.minHours"}})]}}])})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.maxHours,"outlined":"","label":"Maximum hours","rounded":""},model:{value:(_vm.newOffer.maxHours),callback:function ($$v) {_vm.$set(_vm.newOffer, "maxHours", $$v)},expression:"newOffer.maxHours"}})]}}])})],1)],1),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" When is the internship start & deadline date? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('v-row',{staticClass:"d-flex mt-3"},[_c('v-col',{},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date1,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date1=$event},"update:return-value":function($event){_vm.date1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rounded":"","outlined":"","label":"Start Date","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.newOffer.internshipStart),callback:function ($$v) {_vm.$set(_vm.newOffer, "internshipStart", $$v)},expression:"newOffer.internshipStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"min":"2024-06-24","max":"2025-06-31","scrollable":""},model:{value:(_vm.newOffer.internshipStart),callback:function ($$v) {_vm.$set(_vm.newOffer, "internshipStart", $$v)},expression:"newOffer.internshipStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date2,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date2=$event},"update:return-value":function($event){_vm.date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","label":"Deadline Date","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.newOffer.internshipEnd),callback:function ($$v) {_vm.$set(_vm.newOffer, "internshipEnd", $$v)},expression:"newOffer.internshipEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"min":"2024-08-26","max":"2025-08-31","scrollable":""},model:{value:(_vm.newOffer.internshipEnd),callback:function ($$v) {_vm.$set(_vm.newOffer, "internshipEnd", $$v)},expression:"newOffer.internshipEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('div',{staticClass:"question-title mt-10"},[_vm._v(" When is the last date for the student to accept their offer? "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey lighten-1","small":""}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',[_vm._v("Edit offer presets to adjust template options below")])])],1),_c('v-col',{staticClass:"mt-6 pa-0"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","label":"Acceptance deadline","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.newOffer.acceptanceDeadline),callback:function ($$v) {_vm.$set(_vm.newOffer, "acceptanceDeadline", $$v)},expression:"newOffer.acceptanceDeadline"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"min":"2024-06-01","max":"2024-07-01","scrollable":""},model:{value:(_vm.newOffer.acceptanceDeadline),callback:function ($$v) {_vm.$set(_vm.newOffer, "acceptanceDeadline", $$v)},expression:"newOffer.acceptanceDeadline"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }