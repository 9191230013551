































































import { PropType, defineComponent, reactive, computed } from '@vue/composition-api';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import AcceptStudentDialog from './acceptStudentDialog.vue';

momentDurationFormatSetup(moment);

export default defineComponent({
  name: 'OfferCard',
  components: {
    AcceptStudentDialog
  },
  props: {
    value: {
      required: true,
      type: Object as PropType<Record<string, any>>
    },
    errorCode: {
      required: true,
      type: Number
    }
  },
  setup(props, ctx) {
    const selectedStudent = computed<Record<string, any>>({
      get: () => props.value,
      set: newValue => ctx.emit('input-value', newValue)
    });

    function closeMakeOfferForm() {
      selectedStudent.value.showMakeOfferForm = false;
    }

    const onValidation = (valid: boolean) => {
      if (valid)
        ctx.emit('resolveStudentApplication', {
          studentId: selectedStudent.value._id,
          accept: true,
          newOffer: selectedStudent.value.newOffer
        });
    };

    return {
      selectedStudent,
      closeMakeOfferForm,
      onValidation
    };
  }
});
