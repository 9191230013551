






















































































































































































































































































































































































































































import { defineComponent, reactive, computed, toRefs, ref, set } from '@vue/composition-api';
import { minHours, maxHours } from './const';

export default defineComponent({
  name: 'AcceptStudentDialog',
  props: {
    value: {
      type: Object,
      required: true
    },
    defaultOffer: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    const newOffer = computed<Record<string, any>>({
      get: () => props.value,
      set: newValue => ctx.emit('input-value', newValue)
    });
    newOffer.value.licenseRequirement = Boolean(props.defaultOffer.licenseRequirement);
    newOffer.value.internshipStart = props.defaultOffer.internshipStart;
    newOffer.value.internshipEnd = props.defaultOffer.internshipEnd;
    newOffer.value.minHours = Number(props.defaultOffer.minHours);
    newOffer.value.maxHours = Number(props.defaultOffer.maxHours);
    newOffer.value.employerRecord = Boolean(props.defaultOffer.employerRecord);
    newOffer.value.acceptanceDeadline = props.defaultOffer.acceptanceDeadline;
    newOffer.value.continuation = Boolean(props.defaultOffer.continuation);

    const customState = reactive({
      internshipProject: false,
      position: false,
      compensation: false
    });

    const customValueState = reactive({
      internshipProject: false,
      position: false,
      compensation: false
    });

    const consts = reactive({
      minHours,
      maxHours
    });

    const date = ref('');
    const modal = ref('');
    const date1 = ref('');
    const modal1 = ref('');
    const date2 = ref('');
    const modal2 = ref('');

    function customStateSetter(key: string, value: boolean) {
      set(customState, key, value);
    }

    function customValueSetter(key: string, value: boolean) {
      set(customValueState, key, value);
    }

    return {
      newOffer,
      ...toRefs(consts),
      customState,
      customStateSetter,
      customValueState,
      customValueSetter,
      date,
      modal,
      date1,
      modal1,
      date2,
      modal2
    };
  }
});
